<template>
  <div class="personal_center_box">
    <div class="head">
      <div class="operation_box">
        <div class="user_info">
          <div class="info_box">
            <img
              src="../../../assets/u=1765464561,3100748160&fm=26&fmt=auto.webp"
              alt=""
            />
            <span>lin</span>
          </div>
        </div>
        <div class="score_box">
          <div class="my_score">
            <span>0</span>
            <span>我的积分</span>
          </div>
          <van-button
            round
            type="default"
            color="linear-gradient(to bottom, #fadd5b, #e8a206)"
            class="qd_button"
            size="small"
            @click="qdBtn"
            >{{ qdzt }}签到状态</van-button
          >
        </div>

        <div
          class="score_rule"
          @click.capture="isShowScoreRule = !isShowScoreRule"
        >
          <div class="rule_btn">

            <van-icon name="question" /><span>积分规则</span>
          </div>
          <van-dialog
            v-model="isShowScoreRule"
            title="积分规则"
            close-on-click-overlay
          >
            <ul>
              <li>1.每日签到送1积分</li>
              <li>2.首次绑定用户送5积分</li>
              <li>3.微信缴费每1元送1积分</li>
              <li>4.被推荐人绑定用户后，推荐人送3积分</li>
              <li>5.被推荐人首次微信缴费后，推荐人送2积分</li>
            </ul>
          </van-dialog>
        </div>
      </div>
      <div class="score_menu">
        <div @click="inviteBtn">
          <img src="../../../assets/PersonalCenter/ma.png" />
          <span style="vertical-align: middle">邀请有礼</span>
        </div>
        <div @click="shopBtn">
          <img src="../../../assets/PersonalCenter/shop.png" />
          <span style="vertical-align: middle">积分商城</span>
        </div>
      </div>
    </div>
    <div class="score_content">
      <div class="score_tab">

        <van-tabs
          v-model="jfActive"
          title-active-color="#0e76e7"
          line-width="50%"
          color="#0e76e7"
          line-height="1px"
          @click="tabsClick"
        >

          <van-tab title="积分记录">
            <div class="date_list">2021</div>
            <div class="score_list">
   

              <div class="data_list" v-if="jfListData.length > 0">

                <van-collapse v-model="activeNames">
                  <van-collapse-item name="1">
                    <template #title>
                      <div>
                        12月
                        <span>+2</span>
                      </div>
                    </template>
                    <div class="sub_content">
                      <div class="left"><span>+2</span> 2021-12-24</div>
                      <div class="right"><i>每日签到</i></div>
                    </div>
                  </van-collapse-item>
                </van-collapse>
              </div>

              <div v-else-if="jfListData.length <= 0">
                <img src="../../../assets/PersonalCenter/noDataPng.png" />

                <p>暂无数据</p>
              </div>
            </div>
          </van-tab>
          <van-tab title="兑换记录">
            <div class="date_list">2021</div>
            <div class="score_list">

              <div class="data_list" v-if="dhListData.length > 0">

                <van-collapse v-model="activeNames">
                  <van-collapse-item name="1">
                    <template #title>
                      <div>
                        12月
                        <span>+2</span>
                      </div>
                    </template>
                    <div class="sub_content">
                      <div class="left"><span>+2</span> 2021-12-24</div>
                      <div class="right"><i>每日签到</i></div>
                    </div>
                  </van-collapse-item>
                </van-collapse>
              </div>


              <div v-else-if="dhListData.length <= 0">
                <img src="../../../assets/PersonalCenter/noDataPng.png" />

                <p>暂无数据</p>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!-- <div class="footer">
      <img src="../../../assets/PersonalCenter/benner.jpg">
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {


      jfActive: "",
      qdzt: "",
      activeNames: ["1"],

      isShowScoreRule: false,
      jfListData: [],
      dhListData: [],
    };
  },
  methods: {
    qdBtn() {},
    shopBtn() {},
    tabsClick(name, title) {
      console.log(title);
    },

    inviteBtn () {
      this.$router.push('/shareIndex')
    },
    shopBtn () {

    },
  }

};

</script>
 
<style lang = "less" scoped>
.personal_center_box {
  width: 100%;
  height: 100vh;
  background-color: rgb(245, 245, 245);
  /deep/ .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 18px 0;
    margin-bottom: 7px;
    background: url("../../../assets/PersonalCenter/Integral_top.jpg") no-repeat;
    background-size: 100% 100%;
    .operation_box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .user_info {
        flex: 1;
        text-align: left;
        .info_box {
          margin-left: 20px;
          img {
            width: 30px;
            border-radius: 15px;
            vertical-align: middle;
            margin-right: 12px;
          }
          span {
            font-size: 16px;
            color: white;
            vertical-align: middle;
          }
        }
      }
      .score_rule {
        flex: 1;
        font-size: 16px;
        color: white;
        line-height: 30px;
        vertical-align: middle;
        text-align: right;
        .rule_btn {
          margin-right: 20px;
        }
        .van-icon {
          vertical-align: middle;
        }
        .van-dialog {
          color: black;
          ul {
            padding-left: 20px;
            li {
              text-align: left;
              padding: 10px 0;
            }
          }
        }
        .van-dialog__header {
          font-size: 25px;
        }
      }
      .score_box {
        .my_score {
          flex: 0 0 140px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 140px;
          height: 140px;
          background: url("../../../assets/PersonalCenter/top.png") no-repeat;
          background-size: 100% 100%;
          span {
            display: block;
            width: 100%;
            color: #fff;
            padding: 5px 0;
          }
          span:first-child {
            font-size: 30px;
            font-weight: bold;
          }
        }
        .qd_button {
          width: 120px;
          color: #661c1b !important;
          font-size: 18px !important;
          font-weight: bold;
        }
      }
    }
    .score_menu {
      display: flex;
      justify-content: center;
      background-color: #fff;
      border-radius: 25px;
      box-shadow: 0 3px 5px #c5e1fe;
      width: 90%;
      margin-top: 10px;
      padding: 5px 0;
      div {
        width: 45%;
        border: none;
        font-size: 16px;
        text-align: center;
        img {
          vertical-align: middle;
          width: 25px;
          padding-right: 12px;
        }
      }
      div:first-child {
        color: #0e76e7;
        border-right: 1px solid #efefef;
      }
      div:last-child {
        color: #e8a206;
      }
    }
  }
  .score_content {
    width: 100%;
    .date_list {
      width: 100%;
      height: 40px;
      background-color: pink;
    }
    .score_list {
      width: 100%;
      .data_list {
        width: 100%;
        text-align: left;
        .van-collapse-item {
          width: 100%;
          .sub_content {
            display: flex;
            justify-content: space-between;
          }
          span {
            color: #0e76e7;
            font-size: 18px;
            margin-right: 8px;
          }
          i {
            font-style: normal;
          }
        }
      }
      img {
        padding-top: 15px;
        width: 60%;
      }
      p {
        margin: 0;
      }
    }
  }
  /* .footer {
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  } */
}
</style>